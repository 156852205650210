import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NotificationContext } from 'src/context.jsx';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

export function generateUEID() {
  let first = (Math.random() * 46656) | 0;
  let second = (Math.random() * 46656) | 0;
  first = ('000' + first.toString(36)).slice(-3);
  second = ('000' + second.toString(36)).slice(-3);

  return first + second;
}

const Notification = React.memo(({ children, remove, type }) => {
  const removeRef = useRef();
  const { t } = useTranslation();
  removeRef.current = remove;

  useEffect(() => {
    const duration = 10000;
    const id = setTimeout(() => removeRef.current(), duration);

    return () => clearTimeout(id);
  }, []);

  return (
    <Toast>
      <ToastHeader icon={type} toggle={remove}>
        {t('notifications.notification')}
      </ToastHeader>
      <ToastBody>{children}</ToastBody>
    </Toast>
  );
});

export const withToastProvider = (Component) => (props) => {
  const [toasts, setToasts] = useState([]);

  const add = useCallback((content, type = 'info') => {
    const id = generateUEID();
    setToasts((prev) => [...prev, { id, type, content }]);
  }, []);

  const remove = useCallback((id) => {
    setToasts((prev) => prev.filter((t) => t.id !== id));
  }, []);

  return (
    <NotificationContext.Provider value={{ add, remove }}>
      <Component {...props}>
        {toasts.length && (
          <div
            className='toast-wrapper'
            aria-live='polite'
            aria-atomic='true'
            style={{ position: 'relative', zIndex: '2019', width: '' }}
          >
            <div className='toast-sub-wrapper' style={{ position: 'fixed', top: '0', right: '0' }}>
              {toasts.map((t) => (
                <Notification key={t.id} type={t.type} remove={() => remove(t.id)}>
                  {t.content}
                </Notification>
              ))}
            </div>
          </div>
        )}
      </Component>
    </NotificationContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(NotificationContext);
  const swalToast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  return { add: context.add, remove: context.remove, swalToast };
};

export default { withToastProvider, useToast };
