import { gql } from '@apollo/client';

export const WRITE_OFF_ITEMS = gql`
  query writeOffItems($parlors: [Int], $categories: [Int], $from: timestamptz, $to: timestamptz) {
    logs: store_parlorstorelogs(
      where: {
        store_parlorstore: {
          parlor_id: { _in: $parlors }
          store_item: { store_category: { id: { _in: $categories } } }
        }
        created_at: { _gte: $from, _lte: $to }
      }
    ) {
      files: store_parlorstorelogsfiles {
        file
      }
      created_at
      created_by: accounts_user {
        last_name
        middle_name
        first_name
      }
      logs
      store: store_parlorstore {
        parlor: core_parlor {
          name
        }
        item: store_item {
          name
          category: store_category {
            name
            store_category {
              name
            }
          }
        }
      }
      note
      type: store_parlorstorelogtype {
        name
      }
    }
  }
`;

export const WRITE_OFF_TYPES = gql`
  query storeTypes {
    types: store_parlorstorelogtype {
      name
      id
    }
  }
`;

export const FETCH_PARLOR_STORE = (fullSearchFilter) => gql`
query fetchStore($parlorId: Int!) {
    store: store_parlorstore(
      where: {
        parlor_id: { _eq: $parlorId },
        store_item: {
          store_category: {
            core_parlor_store_available_categories: { parlor_id: { _eq: $parlorId } }
          }
          ${!fullSearchFilter ? 'available: {_neq: false}' : ''}
        }
        ${
          !fullSearchFilter
            ? ', _or: [ {store_item: {parent_id: {_is_null: true }}}, { value: { _neq: 0 }}]'
            : ''
        }
      },
      order_by: { value: asc }) {
        id
        parlor_id
        item: store_item {
          id
          attribute: store_attribute {
            name
            id
          }
          itemimages: store_itemimages {
            image
            id
          }
          parent_id
          image
          description
          sell_price
          shop_price
          provided_price
          purchase_price
          available
          pay_full_points
          pay_half_points
          units: store_item_units_news {
            unit: store_unit {
              id
              name
              value
              title
              cost
            }
          }
          category: store_category {
            id
            parent_id
            name
          }
              name
              units
          marketable: store_sellorderitems(limit: 5, where: {store_sell: { parlor_id: {_eq: $parlorId}}}) {
            details: store_sell {
              created_at
            }
          }
          store_orderitems(where: {store_order: {status: {_in: ["500"]}, parlor_id: {_eq: $parlorId}}}) {
            details: store_order {
              status
              created_at
            }
            value
          }
          is_collection
          can_be_produced
          store_componentitems: storeComponentitemsByProducedItemId {
            id
            amountInItem: amount
            item_id
          }
          store_collectionitems {
            id
            amountInCollection: amount
            item: storeItemByItemId {
              id
            }
          }
        }
        value
        propositions_history: store_parlorstorepropositions{
          comment_who_moderated
          comment_who_proposed
          id
          parlor_store_id
          status
          value
          who_moderated_id
          who_proposed_id
          moderated_at
        }
        store_parlorstorecomments {
          id
        }
    }
}`;

export const ALL_GOODS = (queryName) => gql`
${queryName} Goods ($parlor_id: [Int], $categories: [Int]){
  store_addgood (where: {status: {_eq: "new"}, parlor_id: {_in: $parlor_id}, store_item: {store_category: {id: {_in: $categories}}}}) {
    id
    item_id
    old_price
    parlor_id
    parlor: core_parlor {
      name
    }
    status
    value
    store_item {
      name
      category: store_category {
        name
        store_category {
          id
          name
        }
      }
    }
    expenses_expense {
      id
      nds
      photo
      timestamp
      value
      note
    }
  }
}`;

export const EXPENSES = (queryName) => gql`
  ${queryName} expenses($parlorId: Int!, $need_add_to_warehouse: Boolean_comparison_exp = {_eq: true}) {
    expenses_expense(limit: 20, where: { parlor_id: { _eq: $parlorId }, expenses_expensetype: {need_add_to_warehouse: $need_add_to_warehouse} } , order_by: { timestamp: desc }) {
      id
      include_vat
      moderated
      moderation_comment
      accepted
      nds
      note
      parlor_id
      photo
      value
      user_id
      type_id
      timestamp
      category: expenses_expensetype {
        need_add_to_warehouse
        name
    }
    }
  }
`;
export const SELL_HISTORY = gql`
  query fetchSellHistory($item: Int!, $parlorId: Int!) {
    store_item_by_pk(id: $item) {
      store_sellorderitems(where: { store_sell: { parlor_id: { _eq: $parlorId } } }) {
        details: store_sell {
          created_at
          created_by_id
          parlor_id
          id
          seller_id
        }
        order_id
        value
      }
    }
  }
`;

export const SELL_GOODS = (user) => gql`
  query fetchSellHistory(${user ? '$user: Int' : '$parlorId: Int!'} $limit: Int!, $offset: Int!, ) {
    total: store_sell_aggregate(
      where: { 
        ${user ? 'accountsUserBySellerId: { id: {_eq: $user }}' : 'parlor_id: { _eq: $parlorId }'}
        }
    ){
      aggregate {
        count
      } 
    }
    store_sell(
      limit: $limit
      offset: $offset
      where: { 
        ${user ? 'accountsUserBySellerId: { id: {_eq: $user }}' : 'parlor_id: { _eq: $parlorId }'}
        }
      order_by: { created_at: desc }
    ) {
      created_at
      customer_id
      created_by_id
      id
      price
      status
      wallet: transactions_parlorwallet{
        name
        id
      }
      commentForForm: comment_for_form
      created_by: accountsUserBySellerId {
        first_name
        last_name
        id
      }
    }
  }
`;

export const FETCH_SELL_DETAILS = gql`
  query fetchSellDetails($id: Int!, $parlorId: Int!) {
    store_sell: store_sell_by_pk(id: $id) {
      created_at
      customer_id
      created_by_id
      id
      wallet: transactions_parlorwallet {
        name
        id
      }
      commentForForm: comment_for_form
      price
      parlor_id
      status
      price_type
      core_parlor {
        id
        name
        categories: core_parlor_store_available_categories(
          where: { parlor_id: { _eq: $parlorId } }
        ) {
          id
          store_category {
            value: id
            name
            items: store_items(where: { available: { _eq: true } }) {
              value: id
              name
              category_id
              shop_price
              store_amount
              sell_price
              provided_price
              purchase_price
              nds
              parent_id
              image
              description
              is_collection
              variant: store_attribute {
                value: id
                label: name
                amount: value
              }
              store_item_collection_attributes {
                store_collectionattribute {
                  store_collectionattributes_attributes {
                    store_attribute {
                      name
                      id
                      value
                    }
                  }
                  name
                  id
                }
              }
              store_item_units_news {
                store_unit {
                  id
                  name
                  value
                  cost
                  title
                }
              }
            }
          }
        }
      }
      store_sellorderitems {
        id
        item_id
        sell_price
        shop_price
        price
        value
        store_unit {
          id
          name
          value
          cost
        }
        item: store_item {
          name
          units
          nds
          category: store_category {
            id
            name
          }
          store_parlorstores(where: { parlor_id: { _eq: $parlorId } }) {
            value
          }
          variant: store_attribute {
            value: id
            label: name
            amount: value
          }
          item_units: store_item_units_news {
            unit: store_unit {
              id
              name
              value
              cost
              title
            }
          }
        }
        received
      }
    }
  }
`;

export const CONSUMABLES_HISTORY = gql`
  query fetchConsumable($item: Int!, $parlor: Int!) {
    apps_sessions_consumable(
      where: { item_id: { _eq: $item }, apps_sessions_session: { parlor_id: { _eq: $parlor } } }
    ) {
      id
      value
      created_at
      item_id
      session_id
    }
  }
`;

export const FETCH_PARLOR_ITEMS = (
  activeTab,
  fullSearchFilter,
  payHalfPointsFilter,
  payFullPointsFilter,
  categoriesFilter,
  commentsFilter,
  propositionsFilter,
  sorted,
  officialFilter,
  unOfficialFilter,
) => gql`
query fetchStoreItems( $parlorId: Int!, $half_points: Boolean, $full_points: Boolean, $categories: [Int], $from: timestamptz!) {
    store: store_parlorstore(
      where: {
        parlor_id: { _eq: $parlorId },
        ${
          commentsFilter
            ? 'store_parlorstorecomments_aggregate: {count: {predicate: {_gt: 0}}}'
            : ''
        }
        ${propositionsFilter ? `store_parlorstorepropositions: {status: {_eq: new}}` : ''}
        store_item: {
          ${activeTab === 'collections' ? 'is_collection: {_eq: true}' : ''}
          ${activeTab === 'produced-items' ? 'can_be_produced: {_eq: true}' : ''}
          ${categoriesFilter?.length ? 'category_id: {_in: $categories}' : ''}
          ${payFullPointsFilter ? 'pay_full_points: {_eq: $full_points}' : ''}
          ${payHalfPointsFilter ? 'pay_half_points: {_eq: $half_points}' : ''}
          ${officialFilter ? 'unofficial_item: {_eq: false}' : ''}
          ${unOfficialFilter ? 'unofficial_item: {_eq: true}' : ''}

          store_category: {
            core_parlor_store_available_categories: { parlor_id: { _eq: $parlorId } }
          }

          available: {_neq: false}
        }
        ${
          !fullSearchFilter
            ? ', _or: [ {store_item: {store_attribute: {name: {_eq: "main"}}}} , { value: { _neq: 0 }}]'
            : ''
        }
      },
      ${sorted ? `order_by: { ${sorted} }` : 'order_by: { value: desc }'})
      {
        id
        value
        average_remains
        gadget_connection_id
        item: store_item {
          id
          parent: parent_id
          name
          provided_price
          variant: store_attribute {
            name
          }
          marketable: store_sellorderitems_aggregate(where: {
            store_sell: { parlor_id: {_eq: $parlorId}, created_at: { _gte: $from }},
          }) {
            aggregate {
              count
            }
          }
        }
      }
  }`;

export const FETCH_PARLOR_ITEMS_DATA = (sorted) => gql`
  query fetchItemsData($parlorId: Int!, $ids: [Int!], $from: timestamptz!) {
    store: store_parlorstore(
        where: { id: { _in: $ids } }
        ${sorted ? `order_by: { ${sorted} }` : 'order_by: { value: desc }'}
      ) {
      id
      parlor_id
      average_remains
      gadget_connection_id
      accounts_usergadget {
        id
      }
      pricecategory: price_pricecategoryparlorstore {
        percentage
        name
      }
      expirationdates: store_parlorstoreexpirationdates(where: {is_active: {_eq: true}, }) {
        created_at
        end_date
        transactions: store_parlorstoreexpirationdatetransactions(limit: 1, order_by: {id: desc}) {
          quantity
        }
      }
      item: store_item {
        id
        is_expiration_date
        attribute: store_attribute {
          name
          id
        }
        itemimages: store_itemimages {
          image
          id
        }
        parent_id
        image
        description
        sell_price
        shop_price
        provided_price
        purchase_price
        available
        pay_full_points
        pay_half_points
        item_units: store_item_units_news {
          unit: store_unit {
            id
            name
            value
            title
            cost
          }
        }
        category: store_category {
          id
          parent_id
          name
        }
        name
        units
        marketable: store_sellorderitems_aggregate(where: {
          store_sell: { parlor_id: {_eq: $parlorId}, created_at: { _gte: $from }},
        }) {
          aggregate {
            count
          }
        }
        store_orderitems(
          where: { store_order: { status: { _nin: ["100", "150", "1000"] }, parlor_id: { _eq: $parlorId } } }
        ) {
          details: store_order {
            status
            created_at
          }
          value
        }
        is_collection
        can_be_produced
        store_componentitems: storeComponentitemsByProducedItemId {
          id
          amountInItem: amount
          item_id
        }
        store_collectionitems {
          id
          amountInCollection: amount
          item: storeItemByItemId {
            id
          }
        }
      }
      value
      propositions_history: store_parlorstorepropositions {
        comment_who_moderated
        comment_who_proposed
        id
        parlor_store_id
        status
        value
        who_moderated_id
        who_proposed_id
        moderated_at
        created_at
      }
      store_parlorstorecomments {
        id
      }
    }
  }
`;

export const FETCH_PARLOR_ADD_GOOD = () => gql`
  query fetchAddGoods(
    $parlors: [Int!]
    $categories: [Int]
    $from: timestamptz!
    $to: timestamptz!
    $limit: Int
    $offset: Int
  ) {
    store_addgood(
      limit: $limit
      offset: $offset
      where: {
        parlor_id: { _in: $parlors }
        created_at: { _gte: $from, _lte: $to }
        store_item: { store_category: { id: { _in: $categories } } }
      }
      order_by: { created_at: desc }
    ) {
      parlor: core_parlor {
        name
      }
      parlor_id
      item: store_item {
        id
        category: store_category {
          name
          store_category {
            name
          }
        }
        name
      }
      created_by: accounts_user {
        id
        first_name
        last_name
      }
      value
      created_at
    }
  }
`;

export const FETCH_PARLOR_STORE_VALUES = () => gql`
  query fetchParlorStoreValuesLogs($parlor: Int!, $from: timestamptz, $to: timestamptz) {
    logs: store_parlorstorevalueslog(
      where: { parlor_id: { _eq: $parlor }, created_at: { _gte: $from, _lte: $to } }
    ) {
      id
      name
      parlor_id
      value
      created_at
      is_inventory_period
    }
  }
`;
