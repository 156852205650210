import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { FETCH_PARLOR_STORE_VALUES } from './Querries/goodQueries.gql';
import { useDateFilter } from '../../hooks/gql.hooks';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default React.memo(({ parlor }) => {
  const [from, renderFrom] = useDateFilter('from', moment().startOf('month').format('YYYY-MM-DD'));
  const [to, renderTo] = useDateFilter('to', moment().endOf('month').format('YYYY-MM-DD'));

  const {
    data: parlorStoreLogs,
    error,
    loading,
  } = useQuery(FETCH_PARLOR_STORE_VALUES(), {
    variables: {
      parlor: parlor,
      from: moment(from).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      to: moment(to).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    },
    fetchPolicy: 'cache-and-network',
  });

  const newData = useMemo(() => {
    if (!parlorStoreLogs?.logs?.length || error || loading) {
      return {
        totalItemsChart: [],
        totalPurchasePriceChart: [],
      };
    }

    const totalItems = parlorStoreLogs.logs.filter((log) => log.name === 'total_items');
    const totalPurchasePriceStore = parlorStoreLogs.logs.filter(
      (log) => log.name === 'total_purchase_price',
    );

    const totalItemsChart = totalItems.map((el, index, arr) => {
      const previousValue = index > 0 ? arr[index - 1].value : el.value;
      const percentageChange = ((el.value - previousValue) / previousValue) * 100;
      return {
        ...el,
        percentageChange,
      };
    });

    const totalPurchasePriceChart = totalPurchasePriceStore.map((el, index, arr) => {
      const previousValue = index > 0 ? arr[index - 1].value : el.value;
      const percentageChange = ((el.value - previousValue) / previousValue) * 100;
      return {
        ...el,
        percentageChange,
      };
    });

    return {
      totalItemsChart,
      totalPurchasePriceChart,
    };
  }, [parlorStoreLogs, error, loading]);

  return (
    <Row>
      <Col xs={12}>
        <Col xs={12} lg={6}>
          <Row className='m-0 p-0'>
            <Col lg={6}>{renderFrom}</Col>
            <Col lg={6}>{renderTo}</Col>
          </Row>
        </Col>
      </Col>
      <Col xs={12} lg={6}>
        {!!newData.totalItemsChart?.length && (
          <Line
            data={{
              labels: newData.totalItemsChart.map((el) =>
                moment(el.created_at).format('DD-MM-YYYY'),
              ),
              datasets: [
                {
                  label: 'Количетсво товаров',
                  data: newData.totalItemsChart.map((el) => ({
                    x: moment(el.created_at).format('DD-MM-YYYY'),
                    y: Math.round(el.value),
                    percentageChange: el.percentageChange, 
                    status: el.is_inventory_period,
                  })),
                  borderColor: newData.totalItemsChart.map((el) =>
                    el.is_inventory_period ? 'red' : 'rgba(54, 162, 235, 1)',
                  ),
                  pointRadius: 6,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    fontColor: 'green',
                    fontSize: 12,
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.dataset.label || '';
                      if (label) {
                        label += ': ';
                      }
                      if (context.parsed.y !== null) {
                        label += context.parsed.y;
                      }
                      return label;
                    },
                    afterLabel: function (context) {
                      const percentageChange = context.raw.percentageChange.toFixed(2);

                      const status = context.raw.status;
                      return `Изменение на: ${percentageChange}% ${status ?? ""}`;
                    },
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        )}
      </Col>
      <Col xs={12} lg={6}>
        {!!newData.totalPurchasePriceChart?.length && (
          <Line
            data={{
              labels: newData.totalPurchasePriceChart.map((el) =>
                moment(el.created_at).format('DD-MM-YYYY'),
              ),
              datasets: [
                {
                  label: 'Сумма товаров по цене закупки салона',
                  data: newData.totalPurchasePriceChart.map((el) => ({
                    x: moment(el.created_at).format('DD-MM-YYYY'),
                    y: Math.round(el.value),
                    percentageChange: el.percentageChange,
                    status: el.is_inventory_period,
                  })),
                  borderColor: newData.totalPurchasePriceChart.map((el) =>
                    el.is_inventory_period ? 'red' : 'rgba(54, 162, 235, 1)',
                  ),
                  pointRadius: 6,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  labels: {
                    fontColor: 'green',
                    fontSize: 12,
                  },
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.dataset.label || '';
                      if (label) {
                        label += ': ';
                      }
                      if (context.parsed.y !== null) {
                        label += context.parsed.y;
                      }
                      return label;
                    },
                    afterLabel: function (context) {
                      const percentageChange = context.raw.percentageChange.toFixed(2);
                      const status = context.raw.status;

                      return `Изменение на: ${percentageChange}% ${status ?? ""}`;
                    },
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        )}
      </Col>
    </Row>
  );
});
