import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Row, Table, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import moment from 'moment';
import { ApolloProvider, useApolloClient, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { CONSUMABLES_HISTORY } from '../Querries/goodQueries.gql';
import i18next from 'i18next';

const MySwal = withReactContent(Swal);

const HistoryModal = ({ item, parlor }) => {
  const [page, setPage] = useState(0);
  const pageSize = 5;

  const { data } = useQuery(CONSUMABLES_HISTORY, {
    variables: { item: item.item.id, parlor: parlor },
    fetchPolicy: 'cache-and-network',
  });

  const getHistoryItemsByPage = (addedItems, page) => {
    const data = [...addedItems];
    return data
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .slice(page === 0 ? 0 : page * pageSize, page * pageSize + pageSize);
  };

  return (
    <Row
      style={{
        backgroundColor: '#2b383f',
        borderRadius: '8px',
        padding: '0',
      }}
    >
      <Table responsive>
        <thead>
          <tr>
            <th className='text-center'>{i18next.t('routes.session')}</th>
            <th className='text-center'>{i18next.t('stat_7.time_send')}</th>
            <th className='text-center'>{i18next.t('control_motivation.count')}</th>
          </tr>
        </thead>
        <tbody>
          {data?.apps_sessions_consumable &&
            getHistoryItemsByPage(data.apps_sessions_consumable, page)?.map((el) => (
              <tr key={el.id}>
                <td className='text-center'>
                  <a href={`/crm/sessions/${el.session_id}`} target='_blank'>
                    {el.session_id}
                  </a>
                </td>
                <td className='text-center'>{moment(el.created_at).format('YYYY-MM-DD')}</td>
                <td className='text-center'>{el.value}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      {data?.apps_sessions_consumable.length / pageSize > 1 && (
        <Col xs={12} className='content d-flex justify-content-center align-items-center'>
          <Pagination>
            <PaginationItem disabled={page <= 0}>
              <PaginationLink onClick={() => setPage((prev) => prev - 1)}>Back</PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink>{page + 1}</PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={page + 1 >= data.apps_sessions_consumable.length / pageSize}>
              <PaginationLink onClick={() => setPage((prev) => prev + 1)}>Next</PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      )}
    </Row>
  );
};

export const ConsumableHistory = React.memo(({ item, parlor }) => {
  const client = useApolloClient();

  const getHistory = useCallback((item) => {
    MySwal.fire({
      title: `${i18next.t('stat_7.history_send')} ${item.item?.name} ${i18next.t(
        'stat_7.at_expenses',
      )}`,
      showCancelButton: false,
      confirmButtonText: i18next.t('buttons.close'),
      html: (
        <ApolloProvider client={client}>
          <HistoryModal item={item} parlor={parlor} />
        </ApolloProvider>
      ),
      width: '800px',
    }).then(() => {});
  }, []);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon
          icon={faListCheck}
          className='cursor-edit'
          onClick={() => getHistory(item)}
        />
      </div>
    </div>
  );
});
