export const findCountryCode = (country_code?: string) => {
  const href = document.location.href;

  if (href === 'tattoo.com') return 'ua';
  if (href === 'tattoo.de') return 'de';
  if (href === 'tattoo.pl') return 'pl';
  if (href === 'tattoo.lt') return 'lt';
  if (href === 'tattoo.hu') return 'hu';

  return country_code || 'ua';
};
