import moment from 'moment';
import { questioning } from 'services/gql/index.jsx';
import { useQuery } from '@apollo/client';
import { UserContext } from '../context';
import { useContext } from 'react';

const createYears = () => {
  const stopYear = 2019;
  const currentYear = moment().year();
  return [...Array(currentYear - stopYear + 1).keys()]
    .map((i) => stopYear + i)
    .map((year) => ({ label: year.toString(), value: year }));
};
export const QuestinaryAssistant = (is_new, includeRoles = true) => {
  const { user } = useContext(UserContext);
  const { data: dataQues, refetch } = useQuery(questioning, {
    variables: { is_new },
    fetchPolicy: 'cache-and-network',
  });
  const userParlors = user?.parlors?.map((el) => el?.value);
  const dataQuestioning = dataQues?.questioning.filter(
    (f) =>
      (dataQues.completed.every(
          (el) => el.questionnaire_id !== f.id || el.status !== true || el.user_id !== user?.id,
        ) &&
        f.questionnaire_parlors.some((parlor) => {
          const parlorIds = parlor?.parlorSetting?.settingparlor_parlors?.map(
            (item) => item.parlor_id,
          );
          return (
            includeRoles &&
            parlor?.parlorSetting?.roles?.includes(user?.role) &&
            parlorIds.some((parlorId) => userParlors?.includes(parlorId))
          );
        }) &&
        f.is_active === true) ||
      (dataQues.completed.every(
          (el) => el.questionnaire_id !== f.id || el.status !== true || el.user_id !== user?.id,
        ) &&
        f?.accesUser?.some((access) => access?.user_id === user?.id) &&
        f.is_active === true),
  );
  return { dataQuestioning, refetch, dataQues };
};
export const PRICES = {
  purchase_price: 'Цена закупки(опт)',
  provided_price: 'Цена продажи (заказ салона)',
  sell_price: 'Цена продажи в салонах',
  shop_price: 'Цена продажи VeAn Shop',
  sell_other_countries_price: 'Цена продажи другие страны',
  wholesale_price: 'Цена оптовой закупки',
};

export const tipsTypes = [
  { value: 'customer', label: 'На усмотрение клиента' },
  { value: 'divide_by_all', label: 'Поровну' },
  { value: 'administrator', label: 'Администратору' },
  { value: 'parlor', label: 'Салону' },
  { value: 'master', label: 'Мастеру' },
];

export const months = [
  { label: 'Январь', value: 1 },
  { label: 'Февраль', value: 2 },
  { label: 'Март', value: 3 },
  { label: 'Апрель', value: 4 },
  { label: 'Май', value: 5 },
  { label: 'Июнь', value: 6 },
  { label: 'Июль', value: 7 },
  { label: 'Август', value: 8 },
  { label: 'Сентябрь', value: 9 },
  { label: 'Октябрь', value: 10 },
  { label: 'Ноябрь', value: 11 },
  { label: 'Декабрь', value: 12 },
];
export const years = createYears();

export const hideSlicesPlugin = {
  afterInit: function(chartInstance) {
    setTimeout(() => {
      if (chartInstance.config.data.hiddenSlices !== undefined) {
        // Iterate all datasets.
        for (let i = 0; i < chartInstance.data.datasets.length; ++i) {
          chartInstance.config.data.hiddenSlices.forEach(function(index) {
            chartInstance.getDatasetMeta(i).data[index].hidden = true;
          });
        }
        chartInstance.update();
      }
    }, 100);
  },
};

export const SMS_STATUS = {
  ACCEPTD: 'сообщение принято системой',
  PENDING: 'сообщение в очереди на отправку',
  INPROGRESS: 'сообщение в обработке',
  SENT: 'сообщение отправлено',
  DELIVRD: 'сообщение доставлено',
  VIEWED: 'сообщение просмотрено',
  EXPIRED: 'истек срок доставки сообщения',
  UNDELIV: 'сообщение не доставлено',
  STOPED: 'сообщение остановлено системой',
  ERROR: 'ошибка отправки сообщения',
  INSUFFICIENTFUNDS: 'недостаточно средств для отправки данного сообщения',
  MODERATION: 'сообщение на модерации',
  RESERVED: 'сообщение зарезервировано системой',
  REFUND: 'сообщение подготовлено к возврату средств',
  INVREQUEST: 'запрос пустой или имеет не верный формат',
  INVACTION: 'действие не задано или не поддерживается',
  INVRECIPIENT: 'не верный получатель',
  INVTEXT: 'текст сообщения отсутствует или не соответствует требованиям',
  INVBUTTON: 'не верный формат кнопки',
  INVIMAGEURL: 'не верный формат ur',
  INVROUTE: 'сообщение не может быть отправлено',
  INVSOURCE: 'не указан или не верный отправитель',
  INVCHANNELS: 'не заданы корректные каналы отправки',
  INVSMSMESSAGE: 'сообщение для канала sms не задано',
  INVVIBERMESSAGE: 'сообщение для канала viber не задано',
  INVMSGID: 'идентификатор не задан или не найден',
};

export const problemsSBKeys = [
  'is_applications',
  'is_passwords',
  'is_router',
  'is_guest_network',
  'is_static_ip',
  'is_wifi_printer',
  'is_driver_printer',
  'is_exist_account_hik',
  'is_account_hik',
  'is_new_phone_pc',
  'is_two_cameras',
  'is_record_flashcard',
  'is_record_registrar',
  'is_internet',
  'is_sensor',
  'is_maintenance',
  'is_security_camera_angle',
  'is_account_ajax_admin_director',
  'is_p2p',
];
export const problemsKeys = [
  'is_printer',
  'is_computer',
  'is_phone',
  'is_transferprinter',
  'is_autoclave',
  'is_tendon',
  'is_consumables',
  'is_chemical',
  'is_room',
  'is_hot',
  'is_cold',
  'is_front',
  'is_advertisement',
  'is_badphoto',
  'is_electricity',
  'is_internet',
  'is_security',
  'is_terminal',
  'is_cash_machine',
  'is_tv_live',
  'is_quartz',
  'is_safe_cash',
  'is_safe_keys',
  'is_cleanliness_parlor',
];

export const equipmentKeys = ['is_blanket', 'is_water', 'is_hot_drinks', 'is_treats', 'is_invalid'];

export const problemsOfficeKeys = [
  'is_team',
  'is_cleanliness',
  'is_sanitary',
  'is_hygiene',
  'is_mood_team',
  'is_localization',
  'is_studying',
  'is_lighting_master',
  'is_branding',
  'is_little_decor',
  'is_showcase_healing',
  'is_showcase_souvenir',
  'is_diplomas_awards',
  'is_service_consultation_client',
  'is_social_networks',
];

export const thresholdSpentConsumables = {
  com: 3.0, 
  pl: 3.0, 
  hu: 3.0, 
  lt: 3.0, 
  nl: 3.5, 
  lv: 3.0,
  cz: 2.0, 
  de: 3.0, 
  en: 2.0, 
}

export const SessionTypes = {
  tattoo: 'tattoo',
  piercing: 'piercing',
  consultation: 'consultation',
  other: 'other',
}


export const receiverTypes = [
  { value: 'employer', label: 'Работник' },
  { value: 'customer', label: 'Клиент' },
  { value: 'country', label: 'Другие страны' },
  { value: 'wholesale', label: 'Оптовые' },

];