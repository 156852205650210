import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip as NativeTooltip } from 'reactstrap';
// import {useToggle} from "../composables/gql.composables"
// import {generateUEID} from "../services/notifications.service"
// import lodash from "lodash"

// const Tooltip = React.memo(({placement, children, tooltipContent}) => {
//     const [opened, toggleOpened] = useToggle(false)
//
//     const id = useMemo(() => generateUEID(), [])
//     const Component = useMemo(() => {
//         return React.cloneElement(React.Fragment, {}, [
//             React.cloneElement(children, {id}),
//             <NativeTooltip placement={placement} isOpen={opened} target={id} toggle={toggleOpened}
//                            style={{zIndex: "9999"}}>
//                 {tooltipContent}
//             </NativeTooltip>
//         ])
//     }, [tooltipContent, placement, opened, toggleOpened, children, id])
//     console.log(Component)
//     return Component
// }, lodash.isEqual)

class Tooltip extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpened: false,
    };
  }
  toggle() {
    this.setState((prevState) => ({ tooltipOpened: !prevState.tooltipOpened }));
  }
  render() {
    const { children, id, placement, tooltipContent, delay, autohide = true } = this.props;
    return (
      <>
        {children}
        {tooltipContent ? (
          <NativeTooltip
            placement={placement}
            isOpen={this.state.tooltipOpened}
            target={id}
            autohide={autohide}
            delay={delay}
            toggle={this.toggle}
            style={{ zIndex: '9999', maxWidth: '400px', width: '100%' }}
          >
            {tooltipContent}
          </NativeTooltip>
        ) : null}
      </>
    );
  }
}

Tooltip.propTypes = {
  placement: PropTypes.string.isRequired,
  tooltipContent: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default Tooltip;
