import React, { useCallback, useRef, useState, memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScissors } from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useTranslation } from 'react-i18next';

const CutImageModal = memo(({ setFieldValue, file, name, value }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const cropperRef = useRef(null);

  const handleCut = useCallback(() => {
    const cropper = cropperRef.current?.cropper;
    const croppedImageData = cropper.getCroppedCanvas().toDataURL('image/jpeg');

    setFieldValue(
      name,
      value.map((v) => {
        if (v.name === file.name) {
          return {
            ...v,
            base64: croppedImageData,
            extension: 'jpeg',
            type: 'image/jpeg',
            name: v.name.replace('.png', '.jpeg'),
          };
        }
        return v;
      }),
    );
    setModal(false);
  }, [file, name, setFieldValue]);

  return (
    <>
      <FontAwesomeIcon
        icon={faScissors}
        className='cursor-edit'
        size='lg'
        style={{ marginRight: '10px' }}
        onClick={toggleModal}
      />
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader>
          {t('extra.crop')} {t('extra.img')}
        </ModalHeader>
        <ModalBody>
          <Cropper src={file.base64} guides={false} ref={cropperRef} zoomable={false} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleCut}>{t('extra.crop')}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
});

export default CutImageModal;
