import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';

export default React.memo(({ setPage, page, pageSize, total }) => {
  const { t } = useTranslation();

  function roundUpIfGreaterThanInteger(number) {
    if (number > Math.floor(number)) {
      return Math.ceil(number);
    } else {
      return number;
    }
  }

  return (
    <Row className='d-flex justify-content-between'>
      <Col xs={2}>
        <PaginationItem disabled={page <= 0}>
          <PaginationLink onClick={() => setPage(0)}>{t('order_list.go_to_start')}</PaginationLink>
        </PaginationItem>
      </Col>
      <Col xs={8}>
        <Pagination className='d-flex justify-content-center' style={{ marginTop: '20px' }}>
          <PaginationItem disabled={page <= 0}>
            <PaginationLink onClick={() => setPage((prev) => prev - 1)}>Back</PaginationLink>
          </PaginationItem>
          <PaginationItem active>
            <PaginationLink>
              {page + 1} / {total && roundUpIfGreaterThanInteger(total / pageSize)}
            </PaginationLink>
          </PaginationItem>
          <PaginationItem
            disabled={page + 1 === roundUpIfGreaterThanInteger(total / pageSize) || !total}
          >
            <PaginationLink onClick={() => setPage((prev) => prev + 1)}>Next</PaginationLink>
          </PaginationItem>
        </Pagination>
      </Col>
      <Col xs={2}></Col>
    </Row>
  );
});
