import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { FormFeedback, FormGroup, Label, FormText } from 'reactstrap';
import { selectStyles } from 'helpers';
import i18next from 'i18next';

export default class Select extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.error !== this.props.error ||
      nextProps.value !== this.props.value ||
      nextProps.options !== this.props.options ||
      nextProps.touched !== this.props.touched
    );
  }

  render() {
    const {
      name,
      options,
      value,
      error,
      onChange,
      handleBlur,
      label,
      touched,
      placeHolder,
      required,
      groupStyle,
      inline,
      ...otherProps
    } = this.props;

    return (
      <FormGroup style={{ ...groupStyle, width: '100%' }}>
        {!inline ? (
          <dl className='row'>
            {label && (
              <dt className='col-md-3'>
                <Label for={name}>{label}</Label>
              </dt>
            )}
            <dd className={label ? 'col-md-9' : 'col-md-12'}>
              <AsyncSelect
                name={name}
                id={name}
                options={options}
                value={value}
                onBlur={handleBlur}
                onChange={onChange}
                className={error && touched ? 'vean__container invalid' : 'vean__container'}
                styles={selectStyles(error && touched)}
                placeholder={placeHolder}
                required={required}
                classNamePrefix='vean'
                {...otherProps}
              />
              {otherProps.helpText && <FormText>{otherProps.helpText}</FormText>}
              {error && touched && <FormFeedback className='d-block'>{error}</FormFeedback>}
            </dd>
          </dl>
        ) : (
          <>
            <Label for={name}>{label}</Label>
            <AsyncSelect
              name={name}
              id={name}
              options={options}
              value={value}
              onBlur={handleBlur}
              onChange={onChange}
              styles={selectStyles(error && touched)}
              placeholder={placeHolder}
              required={required}
              classNamePrefix='vean'
              {...otherProps}
            />
            {otherProps.helpText && <FormText>{otherProps.helpText}</FormText>}
            {error && touched && <FormFeedback className='d-block'>{error}</FormFeedback>}
          </>
        )}
      </FormGroup>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  error: PropTypes.string,
  touched: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  required: PropTypes.bool,
  groupStyle: PropTypes.object,
};

Select.defaultProps = {
  placeHolder: 'Choose from the list...',
  touched: false,
  error: '',
  label: '',
  required: false,
  groupStyle: null,
};
