import React from 'react';
import styled, { keyframes } from 'styled-components';
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(10, 10, 10, 0.5);
  z-index: 1101;
`;

const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const CustomLoader = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 15px solid;
  border-color: transparent;
  border-right-color: #31c3a2;
  animation: ${spinAnimation} 1s infinite linear;
`;

export default React.memo(
  () => {
    return (
      <Container>
        <CustomLoader />
      </Container>
    );
  },
  () => true,
);

export const LoadingSimple = () => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <Container>
        <CustomLoader />
      </Container>
    </div>
  );
};
