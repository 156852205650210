import { gql, useQuery } from '@apollo/client';

export function getProtocol(websocket = false) {
  const { protocol } = window.location;
  if (!websocket) return protocol;
  return protocol === 'https:' ? 'wss:' : 'ws:';
}

export function getApolloDomain() {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_APP_APOLLO;
  }
  const { hostname } = window.location;
  const splited = hostname.split('.');
  if (splited[0] === 'crm') {
    return `gql.${splited[1]}.${splited[2]}/v1/graphql`;
  }
  return `gq.${splited[1]}.${splited[2]}/v1/graphql`;
}

export function getApiDomain(api_v = 1) {
  if (import.meta.env.DEV) {
    return import.meta.env.VITE_APP_API + api_v.toString();
  }
  const { hostname, protocol } = window.location;
  return `${protocol}//${hostname}/api/v${api_v}`;
}

export function getDomain() {
  let port = window.location.port;
  if (import.meta.env.DEV) {
    if (import.meta.env.VITE_LOCAL_SITE_ADMIN_DOMAIN) {
      return import.meta.env.VITE_LOCAL_SITE_ADMIN_DOMAIN;
    } else {
      port = '8000';
    }
  }
  const { hostname, protocol } = window.location;
  if (port) {
    return `${protocol}//${hostname}:${port}`;
  }
  return `${protocol}//${hostname}`;
}

export function getDomainSocket() {
  let port = window.location.port;
  if (import.meta.env.DEV) {
    port = '8000';
  }
  const { hostname } = window.location;
  if (port) {
    return `ws://${hostname}:${port}`;
  }
  return `wss://${hostname}`;
}

const siteDomain = gql`
    query siteDomain {
        django_site {
            domain
        }
    }
`;

export function getTLD() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useQuery(siteDomain, { fetchPolicy: 'cache-and-network' });
  const domain = data?.django_site?.[0]?.domain;
  if (domain) {
    return domain.split('.').slice(-1)[0];
  }
  return null;
}
