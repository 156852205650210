import { useQuery } from '@apollo/client';
import { DEPARTMENTS, TASKLIST_COUNT } from './gql';
import { useContext } from 'react';
import { UserContext } from 'src/context.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';

export const STATUSES = [
  { label: 'Все задачи', value: null },
  { label: 'В работе', value: 'uncompleted' },
  { label: 'В проверке', value: 'completed' },
  { label: 'Завершеные', value: 'moderated' },
  { label: 'Просроченные', value: 'overdue' },
  { label: 'Зачислены', value: 'finally' },
  { label: 'Выплачены', value: 'paid' },
];

export const STATUSES_OPTIONS = {
  uncompleted: 'В работе',
  completed: 'В проверке',
  moderated: 'Завершенные',
  overdue: 'Просроченные',
  finally: 'Зачислены',
  paid: 'Выплачены',
};

export const initialDashboardTask = {
  uncompleted: [],
  completed: [],
  moderated: [],
  overdue: [],
  finally: [],
  paid: [],
};

export const COMPLEXITY_TASK_OPTIONS = {
  easy: 'Легкая',
  medium: 'Средняя',
  hard: 'Сложная',
};

export const colorStatus = {
  uncompleted: '#1d8cf8',
  completed: 'orange',
  moderated: 'green',
  overdue: '#fd5d93',
  finally: '#92c713',
  paid: '#90EE90',
};

export const status_convert = (status) => {
  switch (status) {
    case 'uncompleted':
      return <p style={{ color: '#1d8cf8' }}>{i18next.t('status.in_progress')}</p>;
    case 'completed':
      return <p style={{ color: 'orange' }}>{i18next.t('status.in_verification')}</p>;
    case 'moderated':
      return <p style={{ color: 'green' }}>{i18next.t('status.finished')}</p>;
    case 'overdue':
      return <p style={{ color: '#fd5d93' }}>{i18next.t('status.overdue')}</p>;
    case 'finally':
      return <p style={{ color: '#92c713' }}>{i18next.t('status.points_have_been_awarded')}</p>;
    case 'paid':
      return <p style={{ color: '#90EE90' }}>{i18next.t('status.paid_out')}</p>;
    default:
      return null;
  }
};

export const roles = [
  { label: 'Все роли', value: null },
  { label: 'Управляющие', value: 'director' },
  { label: 'Офис', value: 'office' },
  { label: 'Мастер', value: 'master' },
  { label: 'Служба безопастности', value: 'security' },
  { label: 'Экономисты', value: 'economist' },
  { label: 'Администраторы', value: 'administrator' },
];
export const useDepartments = (select_all = true, isEstimation = null) => {
  const { data } = useQuery(DEPARTMENTS, {
    variables: { isEstimation: isEstimation },
    fetchPolicy: 'cache-and-network',
  });
  return [
    ...(select_all ? [{ label: 'Все отделы', value: null }] : []),
    ...(data?.departments || [])
      .filter((el) => el.name !== 'Test')
      .map((el) => ({
        label: el.name,
        value: el.id,
      })),
  ];
};

export const useCountTask = (navbar = false) => {
  const { user } = useContext(UserContext);
  const { data, loading, error } = useQuery(
    TASKLIST_COUNT,
    {
      variables: { users: user.id },
    },
    { fetchPolicy: 'cache-and-network' },
  );
  if (loading || error) return null;
  const taskCount = data.tasks.aggregate.count;
  if (taskCount === 0) return null;
  if (navbar)
    return (
      <a
        href={'/crm/tasks'}
        className={'d-flex flex-column align-items-center'}
        style={{
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        <span className='notification-badge d-xl-block' style={{ right: '-5px' }}>
          {taskCount}
        </span>
        <FontAwesomeIcon icon={faTasks} style={{ color: 'green' }} size='lg' />
        <p className='d-lg-none mobile-hidden'>Задачи: ({taskCount})</p>
      </a>
    );
  return <span style={{ color: 'green' }}>({taskCount})</span>;
};
