import React, { useContext, useEffect, useState, useCallback } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from 'src/context.jsx';
import { handleAxiosErrorSwal } from 'helpers/handle.helpers';
import { Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
const MySwal = withReactContent(Swal);

const CommentModal = ({ item, api, user }) => {
  const [comments, setComments] = useState({});
  const [page, setPage] = useState(0);
  const pageSize = 5;

  const fetchComments = useCallback(
    async (page) => {
      await api
        .get(
          `/parlor-store-comments/?parlor_store=${item.id}&page=${page + 1}&page_size=${pageSize}`,
        )
        .then(({ data }) => {
          setComments(data);
        })
        .catch(console.log);
    },
    [api, item.id],
  );

  useEffect(() => {
    fetchComments(page);
  }, [page, fetchComments]);

  return (
    <Row
      style={{
        backgroundColor: '#2b383f',
        borderRadius: '8px',
        padding: '10px 0',
      }}
    >
      {comments &&
        comments.results?.reverse().map((c) => {
          return (
            <Col
              xs={12}
              key={c.id}
              className={`d-flex ${
                user.id === c.created_by.id ? 'justify-content-end' : 'justify-content-start'
              }`}
            >
              <Row
                className='justify-content-between  align-items-center'
                style={{
                  width: '90%',
                  margin: '8px',
                  padding: '5px',
                  marginBottom: '15px',
                  border: '1px solid #2caf91',
                  borderRadius: user.id === c.created_by.id ? '8px 8px 0 8px' : '8px 8px 8px 0',
                  backgroundColor: user.id === c.created_by.id ? '#3c9883' : 'none',
                }}
              >
                <Col xs={7}>
                  <p
                    style={{
                      textAlign: 'start',
                      margin: 0,
                      fontSize: '14px',
                      color: user.id === c.created_by.id ? 'black' : 'white',
                    }}
                  >
                    {user.id === c.created_by.id ? 'Вы' : c.created_by.get_full_name}: {c.text}
                  </p>
                </Col>
                <Col xs={3}>
                  <p
                    style={{
                      fontSize: '10px',
                      margin: 0,
                      color: user.id === c.created_by.id ? 'black' : 'white',
                    }}
                  >
                    {c.created_at.split(' ')[0]}
                  </p>
                </Col>
              </Row>
            </Col>
          );
        })}
      {comments.count / pageSize >= 1 && (
        <Col xs={12} className='content d-flex justify-content-center align-items-center'>
          <Pagination>
            <PaginationItem disabled={page <= 0}>
              <PaginationLink onClick={() => setPage((prev) => prev - 1)}>Back</PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink>{page + 1}</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                disabled={page + 1 >= comments.count / pageSize}
                onClick={() => setPage((prev) => prev + 1)}
              >
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      )}
    </Row>
  );
};

export default React.memo(({ item }) => {
  const { user, api } = useContext(UserContext);

  const getComments = useCallback(
    (item) => {
      MySwal.fire({
        title: 'Добавить комментарий',
        input: 'textarea',
        showCancelButton: true,
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Добавить',
        inputValidator: (value) => {
          if (!value) {
            return 'Добавить';
          }
        },
        showLoaderOnConfirm: true,
        html: <CommentModal item={item} api={api} user={user} />,
        preConfirm(text) {
          return api
            .post(`/parlor-store-comments/`, { parlor_store: item.id, text })
            .then(() => Swal.fire('Добавлен!', '', 'success'))
            .catch(handleAxiosErrorSwal);
        },
      }).then((result) => {
        if (result.value) {
          Swal.fire('Добавлен!', '', 'success');
        }
      });
    },
    [api, user],
  );
  return (
    <>
      <p>комментарии ({item.original?.store_parlorstorecomments?.length})</p>
      <FontAwesomeIcon
        icon={faPencilAlt}
        className='cursor-edit'
        onClick={() => getComments(item.original)}
      />
    </>
  );
});
