import React, { useState, useEffect } from 'react';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ScrollUpButton = React.memo(() => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, []);

  return (
    <div
      onClick={scrollToTop}
      className='cursor-edit'
      style={{
        display: visible ? 'inline' : 'none',
        position: 'fixed',
        bottom: 30,
        right: 30,
        scale: '3',
      }}
    >
      <FontAwesomeIcon icon={faArrowCircleUp} size='lg' />
    </div>
  );
});

export default ScrollUpButton;
