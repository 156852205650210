import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';
import moment from 'moment';
import { joinUserNames, getRoleByName } from 'helpers';
import Select from 'components/Select';
import { useDepartments } from '../../Tasks/Components';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { useDateFilter, useToggle } from 'hooks/gql.hooks';
import XLSX from 'xlsx';
import {
  CategoryPointDepartmentAdd,
  CategoryPointDepartmentDirectorReport,
} from 'src/components/CategoryPointDepartmentAdd';
import Tooltip from 'src/components/Tooltip';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalCategoryPointDepartment from './ModalCategoryPointDepartment';

export const COMMENTS_BY = gql`
  query MyQuery {
    user: accounts_user(where: { is_active: { _eq: true } }) {
      first_name
      last_name
      id
      role
      profile: accounts_profile {
        rank
      }
    }
  }
`;
const DEPARTAMENTPOINT = gql`
  query MyQuery($from: timestamptz!, $to: timestamptz!, $depart: Int) {
    depart_point: core_categorypointdepartment(
      order_by: { id: desc }
      where: { created_at: { _gte: $from, _lte: $to }, department_id: { _eq: $depart } }
    ) {
      id
      comment
      created_at
      is_active
      point
      updated_at
      department_id
      depart: core_department {
        id
        name
        category
        category_count
      }
      accounts_user {
        id
        first_name
        last_name
        role
        profile: accounts_profile {
          id
          rank
        }
      }
    }
  }
`;
const columns = (points_to_number) => [
  {
    Header: 'Кто поставил',
    accessor: 'whoSend',
    minWidth: 150,
    Cell: (row) => {
      return (
        <div className='text-center'>
          <Link to={`/crm/profile/${row.original.accounts_user.id}`} target='_blank'>
            {joinUserNames(row.original.accounts_user)} (
            {getRoleByName(row.original.accounts_user.role)})
          </Link>
        </div>
      );
    },
  },
  {
    Header: 'Департамент',
    accessor: 'depart',
    minWidth: 100,
    Cell: (row) => {
      return (
        <div className='text-center'>
          <p>{row.original.depart.name}</p>
        </div>
      );
    },
  },
  {
    Header: 'Оценка',
    accessor: 'point',
    minWidth: 100,
    Cell: (row) => {
      return (
        <div className='text-center'>
          <p>
            {row.original.point} | {points_to_number[row.original.point]}
          </p>
        </div>
      );
    },
    Footer: (data) => {
      const avaragePoint = data?.data.map((el) => points_to_number[el.point]);
      return (
        <div className='text-center'>
          <span>{avaragePoint.reduce((a, b) => a + b, 0) / avaragePoint.length}</span>
        </div>
      );
    },
  },
  {
    Header: 'Комментарий',
    accessor: 'comment',
    minWidth: 250,
    style: { justifyContent: 'flex-start' },
  },
  {
    Header: 'Дата создания',
    accessor: 'date',
    Cell: (row) => {
      return (
        <div className='text-center'>
          <p>{moment(row.original.created_at).format('DD-MM-YYYY HH:mm')}</p>
        </div>
      );
    },
  },
];
export const RatingOffice = () => {
  const { t } = useTranslation();
  const points_to_number = { A: 5, B: 4, C: 3, D: 2 };
  const departament = useDepartments();
  const [modal, toggleModal] = useToggle(false);
  const [from, renderFrom] = useDateFilter('from', moment().startOf('month').format('YYYY-MM-DD'));
  const [to, renderTo] = useDateFilter('to', moment().endOf('month').format('YYYY-MM-DD'));
  const [depart, setDepart] = useState(null);
  const { data } = useQuery(DEPARTAMENTPOINT, {
    variables: {
      from: from,
      to: to,
      depart: depart,
    },
    fetchPolicy: 'cache-and-network',
  });
  const handleExportDepartmentPoint = async () => {
    const newData = data?.depart_point?.map((item) => {
      return {
        'Кто поставил': joinUserNames(item?.accounts_user),
        Департамент: item?.depart?.name,
        Оценка: `${item?.point} | ${points_to_number[item?.point]}`,
        Комментарий: item?.comment,
        'Дата создания': moment(item?.created_at).format('DD-MM-YYYY HH:mm'),
      };
    });

    const ws = XLSX.utils.json_to_sheet(newData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'DepartPoint.xlsx');
  };

  const headOfDepartData = data?.depart_point.filter(
    (f) => f.accounts_user.profile.rank === 'Head of department',
  );
  const directorOwnerData = data?.depart_point.filter(
    (f) =>
      ['director', 'owner'].includes(f.accounts_user.role) &&
      f.accounts_user.profile.rank !== 'Head of department',
  );

  return (
    <Card>
      <CardBody>
        Рейтинги департаментов
        <Row className='mt-2'>
          <Col lg={3}>От:{renderFrom}</Col>
          <Col lg={3}>До:{renderTo}</Col>
          <Col lg={3}>
            Департамент
            <Select options={departament} onChange={(e) => setDepart(e.value)} />
          </Col>
        </Row>
        <Row style={{ display: 'flex' }}>
          <Button color='primary' onClick={() => toggleModal()}>
            Итоговые оценки
          </Button>
          <CategoryPointDepartmentAdd>
            <Button color='primary' href='#b'>
              {'Оценить департамент'}
              <Tooltip
                id='reiting'
                placement='bottom'
                tooltipContent={t('best_parlor.internal_assessment')}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ marginLeft: '0.5rem', color: 'green' }}
                  size='xl'
                  id='reiting'
                />
              </Tooltip>
            </Button>
          </CategoryPointDepartmentAdd>
          {/* <CategoryPointDepartmentDirectorReport>
            <Button color='primary' href='#a'>
              Доп. оценка
            </Button>
          </CategoryPointDepartmentDirectorReport> */}

          <ModalCategoryPointDepartment isOpen={modal} setIsOpenModal={toggleModal} />
        </Row>
        <Row>
          <Col lg={3}>
            <Button onClick={handleExportDepartmentPoint}>Выгрузить в XLSX</Button>
          </Col>
        </Row>
        <div style={{ flex: 1, borderRight: '1px solid #ccc', paddingRight: '10px' }}>
          <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>Директора/Управляющие</h3>
          <ReactTable
            className={'-highlight'}
            columns={columns(points_to_number)}
            showPagination={true}
            minRows={1}
            data={directorOwnerData}
            getTdProps={() => ({
              style: {
                textOverflow: 'inherit',
                whiteSpace: 'normal',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              },
            })}
          />
        </div>
        <div style={{ flex: 1, paddingLeft: '10px' }}>
          <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>Внутренний рейтинг офиса</h3>
          <ReactTable
            className={'-highlight'}
            columns={columns(points_to_number)}
            showPagination={true}
            minRows={1}
            data={headOfDepartData}
            getTdProps={() => ({
              style: {
                textOverflow: 'inherit',
                whiteSpace: 'normal',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              },
            })}
          />
        </div>
      </CardBody>
    </Card>
  );
};
