import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';

const MySwal = withReactContent(Swal);

const HistoryModal = ({ item }) => {
  const [page, setPage] = useState(0);
  const pageSize = 5;
  const getHistoryItemsByPage = (addedItems, page) => {
    const data = [...addedItems];
    return data
      .sort((a, b) => new Date(b.details?.created_at) - new Date(a.details?.created_at))
      .slice(page === 0 ? 0 : page * pageSize, page * pageSize + pageSize);
  };

  return (
    <Row
      style={{
        backgroundColor: '#2b383f',
        borderRadius: '8px',
        padding: '10px 0',
      }}
    >
      {item &&
        getHistoryItemsByPage(item.store_orderitems, page).map((el) => {
          return (
            <Col xs={12} key={el.id} className={`d-flex 'justify-content-between'`}>
              <Row
                className='justify-content-between  align-items-center'
                style={{
                  width: '100%',
                  margin: '8px',
                  padding: '10px',
                  marginBottom: '15px',
                  border:
                    el.details.status === 700 || 2000 ? '1px solid #2caf91' : '1px solid #ffb52c',
                  borderRadius: '8px',
                  backgroundColor: el.details.status === 700 || 2000 ? '#3c9883' : '#ffa500',
                }}
              >
                <Col xs={3}>
                  <p
                    style={{
                      textAlign: 'start',
                      margin: 0,
                      fontSize: '14px',
                      color: 'black',
                    }}
                  >
                    <span style={{ fontSize: '10px' }}>
                      {i18next.t('upcoming_clients.count')}:{' '}
                    </span>
                    {el.value}
                  </p>
                </Col>
                <Col xs={5}>
                  <p
                    style={{
                      textAlign: 'start',
                      margin: 0,
                      fontSize: '14px',
                      color: 'black',
                    }}
                  >
                    <span style={{ fontSize: '10px' }}>статус: </span>
                    {el.details.status === 700 || 2000
                      ? i18next.t('store_stat.accepted')
                      : i18next.t('stat_7.waiting')}
                  </p>
                </Col>
                <Col xs={4}>
                  <p
                    style={{
                      fontSize: '14px',
                      margin: 0,
                      color: 'black',
                    }}
                  >
                    {moment(el.details.created_at).format('DD-MM-YYYY HH:mm')}
                  </p>
                </Col>
              </Row>
            </Col>
          );
        })}
      {item.store_orderitems.length / pageSize >= 1 && (
        <Col xs={12} className='content d-flex justify-content-center align-items-center'>
          <Pagination>
            <PaginationItem disabled={page <= 0}>
              <PaginationLink onClick={() => setPage((prev) => prev - 1)}>Back</PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink>{page + 1}</PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={page + 1 >= item.store_orderitems.length / pageSize}>
              <PaginationLink onClick={() => setPage((prev) => prev + 1)}>Next</PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      )}
    </Row>
  );
};

export const ListAddItems = React.memo(({ item }) => {
  const getHistory = useCallback((item) => {
    MySwal.fire({
      title: i18next.t('stat_7.history_incoming'),
      showCancelButton: false,
      confirmButtonText: i18next.t('buttons.close'),
      html: <HistoryModal item={item} />,
    }).then(() => {});
  }, []);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p style={{ margin: 0 }}>({item.store_orderitems.length})</p>
        <FontAwesomeIcon
          icon={faListCheck}
          className='cursor-edit'
          onClick={() => getHistory(item)}
        />
      </div>
    </div>
  );
});
