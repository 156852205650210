import React, { useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Row, Table, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const MySwal = withReactContent(Swal);

const HistoryModal = ({ item }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const pageSize = 5;

  const getHistoryItemsByPage = (addedItems, page) => {
    const data = [...addedItems];
    return data
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      .slice(page === 0 ? 0 : page * pageSize, page * pageSize + pageSize);
  };

  const getStatus = (status) => {
    if (status === 'new') {
      return { title: t('add_expense.new'), backgroundColor: 'rgba(255, 165, 0, 0.3)' };
    }
    if (status === 'accepted') {
      return { title: t('add_expense.accepted'), backgroundColor: 'rgba(0, 255, 0, 0.3)' };
    }
    if (status === 'canceled') {
      return { title: t('add_expense.rejected'), backgroundColor: 'rgba(255, 0, 0, 0.3)' };
    }
  };
  return (
    <Row
      style={{
        backgroundColor: '#2b383f',
        borderRadius: '8px',
        padding: '0',
        margin: 0,
      }}
    >
      <Table responsive>
        <thead>
          <tr>
            <th className='text-center'>{t('add_expense.count')}</th>
            <th className='text-center'>{t('add_expense.who_suggest')}</th>
            <th className='text-center'>{t('add_expense.who_checked')}</th>
            <th className='text-center'>{t('add_expense.status')}</th>
            <th className='text-center'>{t('add_expense.create')}</th>
          </tr>
        </thead>
        <tbody>
          {item?.propositions_history &&
            getHistoryItemsByPage(item.propositions_history, page)?.map((el) => (
              <tr
                key={el.id}
                style={{
                  backgroundColor: getStatus(el.status).backgroundColor,
                  fontSize: '14px',
                }}
              >
                <td className='text-center'>{el.store_amount ? el.store_amount : el.value}</td>
                <td className='text-center' style={{ maxWidth: '120px' }}>
                  <p>{el.who_proposed_id}</p>
                  {el.comment_who_proposed}
                </td>
                <td className='text-center' style={{ maxWidth: '120px' }}>
                  <p>{el.who_moderated_id}</p>
                  {el.comment_who_moderated}
                </td>
                <td className='text-center' style={{ whiteSpace: 'nowrap' }}>
                  {getStatus(el.status).title}
                </td>
                <td className='text-center' style={{ width: '100px' }}>
                  {moment(el.created_at).format('DD-MM-YYYY HH:mm')}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {item.propositions_history.length / pageSize > 1 && (
        <Col xs={12} className='content d-flex justify-content-center align-items-center'>
          <Pagination>
            <PaginationItem disabled={page <= 0}>
              <PaginationLink onClick={() => setPage((prev) => prev - 1)}>Back</PaginationLink>
            </PaginationItem>
            <PaginationItem active>
              <PaginationLink>{page + 1}</PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={page + 1 >= item.propositions_history.length / pageSize}>
              <PaginationLink onClick={() => setPage((prev) => prev + 1)}>Next</PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      )}
    </Row>
  );
};

export const ChangesList = React.memo(({ item }) => {
  const { t } = useTranslation();
  const getHistory = useCallback((item) => {
    MySwal.fire({
      title: t('add_expense.history_change'),
      showCancelButton: false,
      confirmButtonText: t('buttons.close'),
      html: <HistoryModal item={item} />,
      width: '800px',
    }).then(() => {});
  }, []);
  return (
    <div>
      <div
        style={{
          display: 'flex',
          direction: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p style={{ margin: 0 }}>({item.propositions_history.length})</p>
        <FontAwesomeIcon
          icon={faListCheck}
          className='cursor-edit'
          onClick={() => getHistory(item)}
        />
      </div>
    </div>
  );
});
