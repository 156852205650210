import { useLocale } from '../hooks/gql.hooks.jsx';
import {
  Role,
  CertificatePaymentType,
  CertificateType,
  SessionStatus,
  YEARS_OLD,
} from './enum.helpers.jsx';

export const getStatusName = (statusId) => SessionStatus[statusId];
export const getCertificateType = (typeId) => CertificateType[typeId];
export const getAdvertPaymentType = (typeName) => CertificatePaymentType[typeName];
export const getRoleByName = (role, student) => Role[role](student);
export const getYearsByName = (years) => YEARS_OLD[years];
