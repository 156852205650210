import Swal from 'sweetalert2';

export const handleAxiosError = (actions) => (err) => {
  actions.setSubmitting(false);
  if (err.response) {
    if (err.response.status === 400) {
      const { data } = err.response;
      let errors = {};
      Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
          Object.assign(errors, { [key]: data[key].join('; ') });
        } else if (typeof data[key] === 'object') {
          Object.keys(data[key]).forEach((subkey) => {
            Object.assign(errors, { [subkey]: data[key][subkey].join('; ') });
          });
        }
      });
      if (data.non_field_errors) actions.setStatus({ msg: data.non_field_errors.join('; ') });
      actions.setErrors(errors);
      try {
        document.querySelector(`label[for=${Object.keys(errors)[0]}]`).scrollIntoView();
      } catch (e) {
        alert(JSON.stringify(data));
      }
    } else if (err.response.status === 401) {
      alert('Вы не авторизованы для этого действия. Попробуйте авторизоваться заново.');
    } else if (err.response.status === 500) {
      alert('Ошибка сервера.');
    } else {
      alert('Неизвестная ошибка.');
    }
  }
};

export const handleAxiosErrorSwal = (error) => {
  if (error.response) {
    if (error.response.data.detail || error.response.data.error) {
      if (Array.isArray(error.response.data?.detail)) {
        Swal.fire(
          'Ошибка!',
          error.response?.data?.detail
            .map((el) => Object.values(el))
            .map((el) => el)
            .join(''),
          'error',
        );
      } else {
        Swal.fire(
          'Ошибка!',
          Object.values(error.response?.data?.detail || error.response.data?.error).join(''),
          'error',
        );
      }
    } else if (error.response.data) {
      if (error.response.status === 404) {
        Swal.fire('Ошибка 404', 'Page not found', 'error');
      } else if (error.response.status === 504) {
        Swal.fire(
          'Ошибка 504',
          'Возникает, когда в заданный промежуток времени сервер не получает ответ от другого сервера',
          'error',
        );
      } else if (error.response.status === 502) {
        Swal.fire(
          'Bad Gateway 502',
          'Это временная проблема, когда сервер, выступая в роли шлюза или прокси, не может получить ответ от сервера, к которому он обращается.',
          'error',
        );
      } else if (error.response.status === 503) {
        Swal.fire(
          'Service Unavailable 503',
          'Код ответа на ошибку сервера указывает, что сервер не готов к обработке запроса.',
          'error',
        );
      } else if (error.response.status === 501) {
        Swal.fire(
          'Not Implemented 501',
          'Серверный код ответа на ошибку указывает, что метод запроса не поддерживается сервером и не может быть обработан.',
          'error',
        );
      } else {
        Swal.fire('Ошибка!', Object.values(error.response?.data).join('; '), 'error');
      }
    } else {
      console.log(error);
      Swal.fire('Ошибка!', '', 'error');
    }
  } else {
    console.log(error);
    Swal.fire('Ошибка!', '', 'error');
  }
};

export const handleSwalError = (error) => {
  if (error.response && error.response.status === 400) {
    const { data } = error.response;
    Object.values(data).forEach((errData) => {
      if (Array.isArray(errData) && errData.length) {
        errData.forEach((err) => {
          Swal.fire(err);
        });
      }
    });
  } else {
    Swal.fire(`Request failed: ${error}`);
  }
};
