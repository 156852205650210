import { useEffect, useState } from 'react';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getApolloDomain, getProtocol } from './get_hostname';
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { CachePersistor } from 'apollo3-cache-persist';
import { set, get, del, clear } from 'idb-keyval'; // Импортируйте функции del и clear
import Cookies from 'js-cookie';
import { useLocale } from './hooks/gql.hooks';

const CACHE_LIFETIME = 1 * 60 * 60 * 1000; // 1 hours in milliseconds

const withExpiry = async (method, key, data) => {
  if (method === 'set') {
    const item = {
      data,
      expiry: Date.now() + CACHE_LIFETIME,
    };
    return await set(key, item);
  }

  if (method === 'get') {
    const item = await get(key);
    if (!item) return null;

    if (Date.now() > item.expiry) {
      await del(key);
      return null;
    }
    return item.data;
  }

  if (method === 'remove') {
    return await del(key);
  }

  if (method === 'clear') {
    return await clear();
  }
};

const idbKeyValStorageAdapter = {
  getItem: async (key) => await withExpiry('get', key),
  setItem: async (key, data) => await withExpiry('set', key, data),
  removeItem: async (key) => await withExpiry('remove', key),
  clear: async () => await withExpiry('clear'),
};

export const useClient = () => {
  const [client, setClient] = useState();

  useEffect(() => {
    async function init() {
      const authLink = setContext(async (_, { headers }) => {
        try {
          const savedTokens = Cookies.get('tokens');

          if (!savedTokens) {
            return { headers };
          }

          const parsedTokens = JSON.parse(savedTokens);
          const accessToken = parsedTokens?.access;

          return {
            headers: {
              ...headers,
              authorization: accessToken ? `Bearer ${accessToken}` : '',
            },
          };
        } catch (error) {
          console.error('Ошибка при обработке токенов:', error);
          return { headers };
        }
      });

      const httpLink = new HttpLink({
        uri: `${getProtocol()}//${getApolloDomain()}`,
      });

      const wsLink = new GraphQLWsLink(
        createClient({
          url: `${getProtocol()}//${getApolloDomain()}`,
          connectionParams: async () => {
            const savedTokens = Cookies.get('tokens');
            if (savedTokens) {
              const parsedTokens = JSON.parse(savedTokens);
              const accessToken = parsedTokens?.access;
              return {
                headers: {
                  authorization: accessToken ? `Bearer ${accessToken}` : '',
                },
              };
            }
            return {};
          },
        }),
      );
      const terminatingLink = split(
        ({ query }) => {
          const { kind, operation } = getMainDefinition(query);
          return kind === 'OperationDefinition' && operation === 'subscription';
        },
        wsLink,
        httpLink,
      );
      const link = ApolloLink.from([authLink, terminatingLink]);
      const cache = new InMemoryCache();

      let newPersistor = new CachePersistor({
        cache,
        storage: idbKeyValStorageAdapter,
        trigger: 'write',
      });
      await newPersistor.restore();
      setClient(
        new ApolloClient({
          link,
          cache,
          assumeImmutableResults: true,
        }),
      );
    }

    init();
  }, []);

  return client;
};
