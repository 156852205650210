export const Role = {
  null: () => 'Отсутствует',
  security: () => 'Служба безопасности',
  jivo: () => 'Деловод',
  student: (student) => (student ? 'Ученик' : Role.guest()),
  master: () => 'Мастер',
  administrator: () => 'Администратор',
  director: () => 'Управляющий',
  office: () => 'Офис',
  owner: () => 'Директор',
  guest: () => 'Студент-Аренда места',
  economist: () => 'Экономист',
  accountant: () => 'Проверка рекламы',
  hr: () => 'HR',
  client: () => 'client',
  storekeeper: () => 'Кладовщик',
};
export const YEARS_OLD = {
  null: () => 'Отсутствует',
  up_18_years_old: () => 'до 18',
  for18_24: () => '18-24',
  for25: () => '25+',
};
export const findGender = [
  { label: 'Man', value: 'man' },
  { label: 'Woman', value: 'woman' },
];
export const years_old = [
  { label: 'до 18', value: 'up_18_years_old' },
  { label: '18-24', value: 'for18_24' },
  { label: '25+', value: 'for25' },
];

export const findLanguage = [
  { label: 'Russian', value: 'ru' },
  { label: 'English', value: 'en' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Polish', value: 'pl' },
  { label: 'German', value: 'de' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Czech', value: 'cs' },
  { label: 'Spanish', value: 'es' },
  { label: 'Bulgarian', value: 'bg' },
  { label: 'Dutch', value: 'nl' },
  { label: 'Latvian', value: 'lv' },
  { label: 'French', value: 'fr' },
  { label: 'Italian', value: 'it' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Swedish', value: 'sv' },
  { label: 'Finnish', value: 'fi' },
  { label: 'Danish', value: 'da' },
  { label: 'Norwegian', value: 'no' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Arabic', value: 'ar' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Hindi', value: 'hi' },
  { label: 'Thai', value: 'th' },
  { label: 'Vietnamese', value: 'vi' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Slovakia', value: 'sk' },
  { label: 'Romania', value: 'ro' },
];

export const findAccomodationStatus = [
  { label: 'Местный', value: 'local' },
  { label: 'Турист', value: 'tourist' },
  { label: 'Гостевой', value: 'guest' },
];

export const findPreferedStyles = [
  { label: 'Classic / Realism', value: 'classic' },
  { label: 'Freak / Trash / Anime', value: 'freak' },
  { label: 'Fashion / Art / minimal', value: 'fashion' },
  { label: 'Rock / Hard', value: 'rock' },
  { label: 'Pop / Mas', value: 'pop' },
];

export const findLifestyle = [
  { label: 'Extreme', value: 'extreme' },
  { label: 'Dance', value: 'dance' },
  { label: 'Anime', value: 'anime' },
  { label: 'Game', value: 'game' },
  { label: 'Music', value: 'music' },
  { label: 'LGBT', value: 'lgbt' },
  { label: 'ART / contemporary', value: 'art' },
  { label: 'Family / love', value: 'family' },
  { label: 'Beauty', value: 'beauty' },
  { label: 'Sexuality', value: 'sexuality' },
  { label: 'Masculinity', value: 'masculinity' },
];

export const findWorkCount = [
  { label: 'None', value: null },
  { label: 'There is one', value: 'one' },
  { label: 'Two and more', value: 'two' },
  { label: 'Total inc/pierce', value: 'inc' },
];

export const DEPARTMENTS = [
  { label: 'Все департаменты', value: null },
  { label: 'Контент', value: 'Контент' },
  { label: 'Офис главный', value: 'Офис главный' },
  { label: 'IT', value: 'IT' },
  { label: 'PR', value: 'PR' },
  { label: 'WebCreate', value: 'WebCreate' },
  { label: 'Аудит и мониторинг', value: 'Аудит и мониторинг' },
  { label: 'Контроль качества', value: 'Контроль качества' },
  { label: 'Магазины', value: 'Магазины' },
  { label: 'Маркетинг', value: 'Маркетинг' },
  { label: 'CMM', value: 'CMM' },
  { label: 'Офис Польща', value: 'Офис Польща' },
  { label: 'Продажи', value: 'Продажи' },
  { label: 'Служба безопасности', value: 'Служба безопасности' },
  { label: 'Трудоустройство', value: 'Трудоустройство' },
];

export const ROLES = [
  { label: 'Owner (директор)', value: 'owner' },
  { label: 'Office (офис)', value: 'office' },
  { label: 'Director (управляющий)', value: 'director' },
  { label: 'Administrator (администратор)', value: 'administrator' },
  { label: 'Master (мастер)', value: 'master' },
  { label: 'Student (студент)', value: 'student' },
  { label: 'Jivo', value: 'jivo' },
  { label: 'Security (служба безопасности)', value: 'security' },
  { label: 'Проверка рекламы', value: 'accountant' },
  { label: 'Economist (экономист)', value: 'economist' },
  { label: 'HR', value: 'hr' },
];

export const ROLES_FOR_DIRECTOR = [
  { label: 'Administrator (администратор)', value: 'administrator' },
  { label: 'Master (мастер)', value: 'master' },
  { label: 'Student (студент)', value: 'student' },
];

export const POINTS = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
];
export const GADGET_CATEGORY = [
  { label: 'Все', value: null },
  { label: 'Офис', value: 'office' },
  { label: 'Студии', value: 'studio' },
];
export const ROLES_PRIORATES = {
  owner: 1,
  office: 2,
  security: 2,
  accountant: 2,
  economist: 2,
  hr: 2,
  director: 3,
  administrator: 4,
  master: 5,
  student: 6,
};

export const CertificatePaymentType = {
  free: 'Бесплатно',
  certificate: 'Сертификат',
  cash: 'Наличные',
};

export const CertificateType = {
  1: 'Акционный',
  2: 'Продажа',
};

export const SessionStatus = {
  100: <span className='color-100'>Ожидание</span>,
  200: <span className='color-200'>Новый</span>,
  300: <span className='color-300'>В работе</span>,
  400: <span className='color-400'>Завершен</span>,
  500: <span className='color-500'>Отменен</span>,
};
export const OrderStatus = {
  100: <span className='status green'>Новый</span>,
  150: <span className='status purple'>Комплектуется</span>,
  200: <span className='status yellow'>Ожидается оплата</span>,
  300: <span className='status red'>Отменен</span>,
  400: <span className='status orange'>Оплачен</span>,
  500: <span className='status blue'>Отправлен</span>,
  // 600: "Доставлен",
  700: <span className='status'>Выполнен</span>,
  1000: <span className='status red'>Отменен</span>,
  1500: <span className='status red'>Выполнен плохо</span>,
  2000: <span className='status'>Подтверждён</span>,
};

export const AccountStatementStatus = {
  new: <span className='status red'>Внести</span>,
  wait: <span className='status yellow'>Ожидает проверки</span>,
  confirmed: <span className='status green'>Подтверждено</span>,
};

export const PriceStatic = [
  {
    label: 'Цена закупки(опт)',
    value: 'purchase_price',
  },
  { label: 'Цена продажи (заказ салона)', value: 'provided_price' },
  { label: 'Цена продажи в салонах', value: 'sell_price' },
  { label: 'Цена продажи VeAn Shop', value: 'shop_price' },
  { label: 'Цена продажи другие страны', value: 'sell_other_countries_price' },
  { label: 'Цена оптовой закупки', value: 'wholesale_price' },
];

export const PostCallStatuses = [
  { value: 125, label: 'Перенаправлен' },
  { value: 150, label: 'Не отвечает' },
  { value: 200, label: 'OK' },
  { value: 400, label: 'Завершен' },
  { value: 500, label: 'Проверен' },
  { value: 100, label: 'Ожидает' },
  { value: 175, label: 'Неверный номер' },
  { value: 300, label: 'Неудовлетворен' },
];

export const LATE_NOTE = [
  { value: 100, label: 'Цена в опросе' },
  { value: 125, label: 'Цена в сеансе' },
  { value: 150, label: 'Продажи недовольный клиент' },
  { value: 175, label: 'Продажи запись' },
  { value: 200, label: 'Жалоба обслуживание' },
  { value: 300, label: 'Жалоба на мастера' },
];

export const PARLOR_STATUS = [
  { value: 100, label: 'Салон' },
  { value: 200, label: 'Офис' },
  { value: 300, label: 'Главный офис' },
];
export const NO_TYPICAL_SITUATION = [
  { value: 'not_tipical', label: 'Не типичная ситуация' },
  { value: 'discussion', label: 'Консультация' },
  { value: 'trainee', label: 'Обучение/vip' },
  { value: 'than_15_minutes', label: 'Отсутствие на рабочем месте более 15 минут' },
];
export const STUDENT_STATUS = [
  { value: 'urgently', label: 'Связаться срочно' },
  { value: 'dial_within_three', label: 'Набрать в течении 3 часов' },
  { value: 'contact_during_day', label: 'Связаться в течении дня' },
  { value: 'contact_within_week', label: 'Связаться в течении недели' },
];

export const SELLER_STATUS = [
  { label: 'Первое касание', value: 'first_touch' },
  { label: 'Второе касание', value: 'second_touch' },
  { label: 'Продал', value: 'sold' },
  { label: 'Непродал', value: 'unsold' },
  { label: 'Нет контактов для связи', value: 'no_contact_details' },
  { label: 'Повторная обработка', value: 'retreatment' },
];

export const MONTH_CHOICES = [
  { label: 'January', value: 'january' },
  { label: 'February', value: 'february' },
  { label: 'March', value: 'march' },
  { label: 'April', value: 'april' },
  { label: 'May', value: 'may' },
  { label: 'June', value: 'june' },
  { label: 'July', value: 'july' },
  { label: 'August', value: 'august' },
  { label: 'September', value: 'september' },
  { label: 'October', value: 'october' },
  { label: 'November', value: 'november' },
  { label: 'December', value: 'december' },
];

export const CUSTOMER_CART = [
  { label: 'Новый', value: 'new_order', color: 'yellow' },
  { label: 'Продан', value: 'sold', color: 'green' },
  { label: 'Отменил', value: 'canceled', color: 'red' },
];

export const CHECK_BOX_SB = [
  { label: 'Не проходил опросник', value: 'pass_questions' },
  { label: 'Прошел без нарушений', value: 'passed_without_hitch' },
  { label: 'Прошел с нарушениями', value: 'passed_with_irregularities' },
];

export const WORK_APPROVED = [
  { label: 'Все', value: null },
  { value: 'wait', label: 'Ожидает', color: 'yellow' },
  { value: 'approved', label: 'Подтверждено', color: 'green' },
  { value: 'rejected', label: 'Отклонено', color: 'red' },
];

export const NEWS_TYPE = [
  { label: 'Vacancies', value: 'vacancies' },
  { label: 'Skill box', value: 'skill_box' },
  { label: 'Digest', value: 'digest' },
  { label: 'Innovations', value: 'innovations' },
  { label: 'Crm update', value: 'crm_update' },
  { label: 'Brief', value: 'brief' },
];

export const LESSON_TYPES = [
  { label: 'Теория', value: 'theory' },
  { label: 'Практика на искусственной коже', value: 'practick' },
  { label: 'Модель', value: 'model' },
];

export const MODER_ALTERNATIVE_STATUS = [
  { label: 'Ожидает', value: 'pending', color: 'yellow' },
  { label: 'Подтверждено', value: 'approved', color: 'green' },
  { label: 'Отклонено', value: 'rejected', color: 'red' },
];

export const TYPE_CONFIRMED = [
  { label: 'Google', value: 'google_confirmed' },
  { label: 'Apple', value: 'apple_confirmed' },
  { label: 'SMS', value: 'confirmed' },
  { label: 'Email', value: 'email_confirmed' },
  { label: 'Agreement', value: 'agreement_confirmed' },
];

export const DISPUTE_STATUS = [
  { label: 'На рассмотрении', value: 'in_review' },
  { label: 'Штраф утвержден', value: 'fine_approved' },
  { label: 'Штраф отменен', value: 'fine_canceled' },
  { label: 'Спор закрыт создателем', value: 'dispute_closed_creator' },
];
export const CHAT_CATEGORY = [
  { label: 'Штраф', value: 'penalty' },
  { label: 'Обсуждение', value: 'discussion' },
  { label: 'Личный', value: 'personal' },
  { label: 'Прочее', value: 'other' },
];

export const LANGUAGE_SKILL = {
  polish: 'PL',
  english: 'EN',
  spanish: 'ES',
  french: 'FR',
  german: 'DE',
  russian: 'RU',
  italian: 'IT',
  belarusian: 'BY',
  ukrainian: 'UA',
  czech: 'CZ',
  hungarian: 'HU',
  bulgarian: 'BG',
  dutch: 'NL',
  lithuanian: 'LT',
  latvian: 'LV',
  slovak: 'SK',
  kazakh: 'KZ',
};

export const languageChoices = [
  { value: 'polish', label: 'PL' },
  { value: 'english', label: 'EN' },
  { value: 'spanish', label: 'ES' },
  { value: 'french', label: 'FR' },
  { value: 'german', label: 'DE' },
  { value: 'russian', label: 'RU' },
  { value: 'italian', label: 'IT' },
  { value: 'belarusian', label: 'BY' },
  { value: 'ukrainian', label: 'UA' },
  { value: 'czech', label: 'CZ' },
  { value: 'hungarian', label: 'HU' },
  { value: 'bulgarian', label: 'BG' },
  { value: 'dutch', label: 'NL' },
  { value: 'lithuanian', label: 'LT' },
  { value: 'latvian', label: 'LV' },
  { value: 'slovak', label: 'SK' },
  { value: 'kazakh', label: 'KZ' },
];
