import React, { useContext, useCallback } from 'react';
import Swal from 'sweetalert2';
import { UserContext } from 'src/context.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { handleAxiosErrorSwal } from 'helpers/handle.helpers';
import { useTranslation } from 'react-i18next';

export default React.memo((props) => {
  const { t } = useTranslation();
  const { api } = useContext(UserContext);
  const editItemAmountInCollection = useCallback(() => {
    Swal.fire({
      title: t('swal.change_count'),
      input: 'number',
      showCancelButton: true,
      cancelButtonText: t('buttons.cancel'),
      confirmButtonText: t('buttons.save'),
      inputValue: props.item.amountInCollection,
      showLoaderOnConfirm: true,
      preConfirm(amount) {
        return api
          .patch(`/collection-item-update/${props.item.collectionItemId}/`, { amount })
          .then((res) => res)
          .catch(handleAxiosErrorSwal);
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire(t('swal.success'), '', 'success');

        props.callback();
      }
    });
  }, [api]);

  return (
    <FontAwesomeIcon
      icon={faEdit}
      className='cursor-edit'
      onClick={() => editItemAmountInCollection()}
    />
  );
});
