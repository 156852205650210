import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import 'assets/css/authentication.css';
import 'assets/scss/login-page.scss';
import { handleAxiosErrorSwal } from 'helpers/handle.helpers';
import { Container, Row, Col, Card, CardBody, Form, Button, Input, Alert } from 'reactstrap';
import { fetchAvailableParlorsByUsername } from './gql';
import Select from 'components/Select';
import axios from 'axios';
import { ParlorContext, UserContext } from 'src/context.jsx';
import { Navigate } from 'react-router-dom';
import { getApiDomain } from 'src/get_hostname';
import { faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import inkSideTattoo from 'assets/image/ink-side_tattoo.svg';
import FormTicket from '../Dashboard/Ticket/Support/Form';
import vean from 'assets/image/vean.png';
import ForgotPassword from './ForgotPassword';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});
const fetchParlors = (
  client,
  username,
  setParlors,
  setStep,
  setData,
  setError,
  fetchTotpAndDeviceStatus,
) => {
  return client
    .query({
      query: fetchAvailableParlorsByUsername,
      variables: { username },
    })
    .then((res) => {
      const { data } = res;
      if (data.accounts_user.length) {
        try {
          const { role } = data.accounts_user[0];
          const parlors = data.accounts_user[0].accounts_profile.accounts_profile_parlors.map(
            (parlor) => ({
              label: parlor.core_parlor.name,
              value: parlor.core_parlor.id,
            }),
          );
          if (parlors.length > 1 && ['master', 'administrator'].includes(role)) {
            setParlors(parlors);
            setStep((p) => p + 1);
          } else if (parlors.length && ['master', 'administrator'].includes(role)) {
            setStep((p) => p + 1);
            setParlors(parlors);
            setData((d) => ({ ...d, parlor: parlors[0] }));
          } else {
            setStep((p) => p + 1);
          }
          fetchTotpAndDeviceStatus();
        } catch (e) {
          console.log(e);
        }
      } else {
        setError('username', i18next.t('swal.user_not_found'));
      }
    })
    .catch(console.log);
};

const Authentication = (props) => {
  const [captchaVisible, setCaptchaVisible] = useState(false);
  const navigate = useNavigate('');
  const { user, tokens, setTokens, api, api_v2 } = useContext(UserContext);
  const { setParlor } = useContext(ParlorContext);
  const client = useApolloClient();
  const [requireOtp, setRequireOtp] = useState(false);
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [parlors, setParlors] = useState([]);
  const [data, setData] = useState({ username: '', password: '', otp: '' });
  const [errors, rawSetError] = useState({
    username: '',
    password: '',
    parlor: '',
    otp: '',
    non_field_errors: '',
  });
  const setError = (k, v) => rawSetError((p) => ({ ...p, [k]: v }));
  const next = useMemo(() => new URLSearchParams(window.location?.search.slice(1)), []);
  const redirectURL = useMemo(
    () => (next.has('next') ? next.get('next') : '/crm/dashboard'),
    [next],
  );
  const [loading, setLoading] = useState(false);
  const [eyePassword, setEyePassword] = useState(true);

  const fetchTotpAndDeviceStatus = () => {
    api
      .get('check-device-status/', {
        params: { username: data?.username },
      })
      .then((response) => {
        console.log(response);
        setRequireOtp(response.data.hasConfirmedTotp && !response.data.isAuthorizedDevice);
      })
      .catch((error) => {
        Swal.fire(
          String(error.response?.statusText || 'Error'),
          String(error.response?.status || '500'),
          'error',
        );
        setRequireOtp(false);
      });
  };

  const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const csrftoken = getCookie('csrftoken');
  const verifyCaptcha = (response) => {
    api_v2
      .post(
        '/verify-captcha/',
        { recaptcha_response: response, username: data.username },
        {
          headers: {
            'X-CSRFToken': csrftoken,
          },
        },
      )
      .then(() => {
        setCaptchaVisible(false);
        Toast.fire({
          icon: 'success',
        });
      })
      .catch(() => {
        Toast.fire({
          icon: 'error',
          title: 'Incorrect captcha',
        });
      });
  };

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      if (step === 1) {
        fetchParlors(
          client,
          data.username,
          setParlors,
          setStep,
          setData,
          setError,
          fetchTotpAndDeviceStatus,
        )
          .then(() => setLoading(false))
          .catch((error) => {
            console.error(error);
            setLoading(false);
          });
      } else {
        if (parlors.length && !data.parlor.value) {
          setError('parlor', t('auth.choose_parlor'));
          setLoading(false);
          return;
        } else {
          const { username, password, otp } = data;
          api
            .post(`/auth/jwt/obtain/`, { username, password, otp })
            .then((response) => {
              setTokens(response.data);
              setParlor(parlors);
              navigate(redirectURL);
              window.location.reload();
            })
            .catch((e) => {
              const captcha_required = e.response?.data?.detail?.captcha_required;
              setCaptchaVisible(e.response?.data?.detail?.captcha_required);
              if (!captcha_required) {
                handleAxiosErrorSwal(e);
              }
            })
            .finally(() => setLoading(false));
        }
      }
    },
    [step, client, data, parlors, t, api, setTokens, setParlor, redirectURL, navigate],
  );

  const onChange = useCallback((event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'username') setStep(1);
    setData((d) => ({ ...d, [name]: value }));
    setError(name, '');
  }, []);

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  if (user && tokens) return <Navigate replace to={redirectURL} />;

  return (
    <Container fluid className='min-vh-100 ground_logo'>
      <Particles
        id='tsparticles'
        init={particlesInit}
        options={{
          fpsLimit: 60,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: 'push',
              },
              onHover: {
                enable: false,
                mode: 'repulse',
              },
              resize: false,
            },
            modes: {
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: '#ffffff',
            },
            links: {
              color: '#ffffff',
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: 'none',
              enable: true,
              outModes: {
                default: 'bounce',
              },
              random: false,
              speed: 6,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }}
      />
      <Row className='align-items-center min-vh-100'>
        <Col sm='12' md={{ size: 4, offset: 4 }}>
          {captchaVisible && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1050,
              }}
            >
              <ReCAPTCHA
                style={{ display: 'flex', justifyContent: 'center' }}
                sitekey='6Lf9bM8pAAAAAEycLZJa6iKpG5_DPqseQFoMGCXU'
                onChange={(response) => {
                  verifyCaptcha(response);
                }}
              />
            </div>
          )}
          <Card className='login-box'>
            <CardBody>
              <Form onSubmit={handleSubmit} className='d-flex flex-column align-items-center'>
                {window.location.href.startsWith('https://crm.ink-side-tattoo.com/') ? (
                  <img style={{ borderRadius: '0px' }} src={inkSideTattoo} alt='...' />
                ) : (
                  <img
                    style={{ borderRadius: '0px', width: '100px', height: '110px' }}
                    src={vean}
                    // src='https://vean-tattoo.com/static/assets/img/logo.svg'
                    alt='...'
                  />
                )}

                {/* <h1 className='logo-caption'>Login</h1> */}

                {errors.non_field_errors && (
                  <div className='d-block invalid-feedback mb-3'>{errors.non_field_errors}</div>
                )}
                <div className='wrap-input100'>
                  <Input
                    className='input100'
                    name='username'
                    id='username'
                    autoFocus
                    value={data.username}
                    onChange={onChange}
                    invalid={errors.username}
                  />

                  <FontAwesomeIcon
                    icon={faUserFriends}
                    size='lg'
                    style={{ position: 'absolute', right: 0, top: '13px' }}
                  />

                  <span
                    className={`focus-input100 ${data.username && 'focus_after'}`}
                    data-placeholder={t('auth.name')}
                  />

                  {errors.username && (
                    <div className='components/TooltipNew'>{errors.username}</div>
                  )}
                </div>
                {step === 2 && (
                  <>
                    {parlors.length > 1 && (
                      <Select
                        label={t('auth.parlor')}
                        inline={true}
                        className='mb-3'
                        classNamePrefix='vean'
                        name='parlor'
                        options={parlors}
                        error={errors.parlor}
                        touched={true}
                        onChange={(option) => setParlors(option?.value)}
                        handleBlur={() => undefined}
                      />
                    )}
                  </>
                )}
                <div className='wrap-input100' style={{ display: step !== 2 && 'none' }}>
                  <Input
                    className='input100'
                    name='password'
                    id='password'
                    type={eyePassword ? 'password' : 'text'}
                    // placeholder="Пароль..."
                    value={data.password}
                    onChange={onChange}
                    invalid={errors.password}
                  />
                  <FontAwesomeIcon
                    onClick={() => setEyePassword(!eyePassword)}
                    icon={eyePassword ? faEye : faEyeSlash}
                    size='lg'
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      right: 0,
                      top: '13px',
                    }}
                  />
                  <span
                    className={`focus-input100 ${data.password && 'focus_after'}`}
                    data-placeholder={t('auth.password')}
                  />
                  {errors.password &&
                    (<div className='components/TooltipNew'>{errors.password}</div>)``}
                </div>
                {requireOtp && (
                  <div className='wrap-input100' style={{ display: step !== 2 && 'none' }}>
                    <Input
                      className='input100'
                      name='otp'
                      id='otp'
                      type='text'
                      value={data.otp}
                      onChange={onChange}
                      invalid={errors.otp}
                    />
                    <span
                      className={`focus-input100 ${data.otp && 'focus_after'}`}
                      data-placeholder={'OTP'}
                    />
                    {errors.otp && <div className='components/TooltipNew'>{errors.otp}</div>}
                  </div>
                )}

                <Button type='submit' className='w-100' disabled={loading}>
                  {loading ? t('auth.get_data') : step === 1 ? t('auth.next') : t('auth.log_in')}
                </Button>

                <div className='d-flex justify-content-center flex-column'>
                  <hr align='center' width='300' size='2' color='white' />
                  <a
                    href='https://customer.vean-tattoo.com/error-ticket'
                    target='_blank'
                    style={{ fontSize: '16px', cursor: 'pointer' }}
                    className='text-center text-secondary'
                  >
                    {t('auth.need_help')}
                  </a>
                </div>
                <ForgotPassword api_v2={api_v2} />
                <br />
                <strong style={{ textAlign: 'center' }}>
                  Для оптимальной работы с CRM рекомендуется использовать настольный компьютер.
                </strong>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Authentication;
