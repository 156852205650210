import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Form } from 'reactstrap';
import { useToggle, useGangetByParlor, useGadgetsName } from '../../../hooks/gql.hooks';
import { Formik, Field } from 'formik';
import { Select as FormikSelect } from 'components/Forms/FormikComponents';
import AddGadget from '../../Stats/GadgetsCheckTable/AddGadget';
import { useContext } from 'react';
import { UserContext } from '../../../context';
import Swal from 'sweetalert2';
import { handleAxiosErrorSwal } from 'helpers';
import i18next from 'i18next';

const AddToActive = ({ data, parlor, price }) => {
  const { api } = useContext(UserContext);
  const [isOpen, toggelOpen] = useToggle(false);
  const { gadgetsData, LoadData } = useGangetByParlor(parlor);
  const gadgetsName = useGadgetsName();
  const mergeGadgetStorage = (values) => {
    api
      .patch(`/parlor-store/${data.original.id}/`, {
        gadget_connection: values.gadgets.value,
      })
      .then(() => {
        Swal.fire(i18next.t('swal.success'), '', 'success');
        toggelOpen();
      })
      .catch(handleAxiosErrorSwal);
  };
  return (
    <div>
      <Button size='sm' onClick={toggelOpen}>
        Добавить
      </Button>
      <Modal size='lg' toggle={toggelOpen} isOpen={isOpen}>
        <ModalHeader toggle={toggelOpen}>Связать с активами</ModalHeader>
        <Formik onSubmit={mergeGadgetStorage} initialValues={{ merge: 'merge', gadgetsName: [] }}>
          {({ handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <ModalBody>
                <Field
                  name='merge'
                  label='Создать новый или свзять с существующим'
                  component={FormikSelect}
                  options={[
                    { label: 'Связать', value: 'merge' },
                    { label: 'Создать новый', value: 'new' },
                  ]}
                  onChange={(value) => {
                    if (value.value === 'merge') {
                      LoadData();
                    }
                  }}
                />
                {values?.merge?.value === 'merge' ? (
                  <>
                    <Field
                      name='gadgetsName'
                      label='Тип устройства'
                      options={gadgetsName}
                      component={FormikSelect}
                    />
                    <Field
                      name='gadgets'
                      label='Устройства'
                      component={FormikSelect}
                      options={gadgetsData.filter((f) =>
                        values?.gadgetsName ? f.type === values.gadgetsName.value : true,
                      )}
                    />
                  </>
                ) : (
                  values?.merge?.value === 'new' && (
                    <AddGadget
                      harmonization={true}
                      price={price}
                      parlorStoreId={data.original.id}
                      toggelOpen={toggelOpen}
                    />
                  )
                )}
              </ModalBody>
              <ModalFooter>
                <Button color='danger' onClick={toggelOpen}>
                  Закрыть
                </Button>
                <Button color='primary' type='submit'>
                  Связать
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
export default AddToActive;
