import { gql } from '@apollo/client';

// accounts_profile_parlors(where: {core_parlor: {}}) {
export const fetchAvailableParlorsByUsername = gql`
  query fetchUserAvailableParlors($username: String!) {
    accounts_user(where: { username: { _eq: $username } }) {
      id
      role
      accounts_profile {
        id
        accounts_profile_parlors {
          id
          core_parlor {
            id
            name
          }
        }
      }
    }
  }
`;
