import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './Loading';

export const IconButton = ({ onClick, children, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <FontAwesomeIcon {...props} onClick={handleClick} disabled={true}>
          {children}
        </FontAwesomeIcon>
      )}
    </>
  );
};
